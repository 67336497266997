   
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from "./components/common/Footer";
import Navbar from "./components/common/Navbar";
import Home from "./components/Homepage/Home";
import Programs from './components/Programs/Programs';
import ProgramDetail from './components/Programs/ProgramDetail';
import RegisterForm from './components/auth/RegisterForm';
import LoginForm from './components/auth/LoginForm';
import Welcome from './components/auth/Welcome';
import CourseDetail from './components/course/CourseDetail';
import DownloadLesson from './components/course/DownloadLesson';
import AboutPage from './components/about/AboutPage';
import Courses from './components/course/Courses';
import Contact from './components/Contact';
import ShowHeader from './components/common/ShowHeader';
import CookieBanner from './CookieBanner';
import AdminDashBoard from './components/auth/admin/AdminDashBoard';
import AllPrograms from './components/auth/admin/allprograms/AllPrograms';
import ExecutorDashboard from './components/auth/salesExecutors/ExecutorDashboard';
import RouteTop from './RouteTop';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import UploadImage from './components/auth/admin/imagecontrol/UploadImage';
import ImageGallery from './components/auth/admin/imagecontrol/ImageGallery';
import {useEffect, useState } from 'react';
import Dashboard from './components/auth/Dashboard';
import AdminCp from './components/auth/AdminCp';
import TopbarUser from './components/common/TopbarUser';
import ShowTopbar from './components/common/ShowTopbar';
import AllCourses from './components/auth/admin/allcourses/AllCourses';
import AddNewCourse from './components/auth/admin/addcourse/AddNewCourse';
import UpdateProgram from './components/auth/admin/addprogram/UpdateProgram';
import UpdateUser from './components/auth/UpdateUser';
import MakeCategory from './components/auth/admin/programcourse/MakeCategory';
import AllEvents from './components/auth/admin/events/AllEvents';
import AddPromotion from './components/auth/admin/addpromotion/AddPromotion';

import AddNewProgram from './components/auth/admin/addprogram/AddNewProgram';
import CreateNewUser from './components/auth/admin/adduser/CreateNewUser';
import LatestEvent from './components/events/LatestEvent';
import Register from './components/channelpartners/auth/Register';
import ImageManagement from './components/auth/admin/imagecontrol/ImageManagement';
import UpdateCourse from './components/auth/admin/addcourse/UpdateCourse';
import AddNewLesson from './components/auth/admin/addlesson/AddNewLesson';
import AddNewUnit from './components/auth/admin/addunit/AddNewUnit';
import AddNewPrerequisite from './components/auth/admin/addprerequsite/AddNewPrerequisite';
import GetAllProgramswithImage from './components/Programs/GetAllProgramswithImage';
import ProgramDetailwithImages from './components/Programs/ProgramDetailwithImages';
import AllPromotions from './components/auth/admin/promotions/AllPromotions';
import GetImagesAws from './components/Programs/GetImagesAws';
// import ChatBotFunc from './ChatBotFunc';
import CPdashboard from './components/channelpartners/auth/CPdashboard';
import ExecutorCp from './components/auth/ExecutorCp';
import SubscriberEvents from './components/auth/SubscriberEvents';
import AuthCp from './components/auth/AuthCp';

import AllLessons from './components/auth/admin/alllessons/AllLessons';
import UpdateLesson from './components/auth/admin/addlesson/UpdateLesson';
import AllUnits from './components/auth/admin/allunits/AllUnits';
import UpdateUnit from './components/auth/admin/addunit/UpdateUnit';
import AllPrerequisites from './components/auth/admin/allprerequisites/AllPrerequisites';
import UpdatePrerequisite from './components/auth/admin/addprerequsite/UpdatePrerequisite';
import EventRegistrationForm from './components/events/EventRegistrationForm';
import ChannelPatner from './components/auth/admin/channelpatner/ChannelPatner';
import UpdateEvent from './components/auth/admin/events/UpdateEvent';
import EventList from './components/auth/admin/events/EventList';
import JobsPage from './components/jobs/JobsPage';
import JobDetailsPage from './components/jobs/JobDetails';
import AllFaqs from './components/auth/admin/allfaqs/AllFaqs';
import AddNewFaq from './components/auth/admin/addfaq/AddNewFaq';
import UpdateFaq from './components/auth/admin/addfaq/UpdateFaq';
import CPLoginForm from './components/channelpartners/auth/CPLoginForm'
import CPRegisterForm from './components/channelpartners/auth/Register';
import CPDashboard from './components/channelpartners/auth/CPdashboard';
import UploadEvent from './components/auth/admin/events/UploadEvent';
import JobUploaderForm from './components/auth/admin/jobUploaderForm/JobUploaderForm';
import EventSubscriber from './components/auth/EventSubscriber/EventSubscriber';
import ForgotPassword from './components/auth/ForgotPassword';
import VerificationCode from './components/auth/verificationcode';
import ChangePassword from './components/auth/changepassword';
import Tutorials from './components/tutorials/Tutorials';
import TutorialDetail from './components/tutorials/TutorialDetail';
import Onlineclasses from './components/Homepage/Onlineclasses';
import Demo from './components/Homepage/Demo';
import OnlineClassRegistration from './components/Homepage/OnlineClassRegistration';
import RemindMe from './components/Homepage/RemindMe';
import Interview from "./components/interview_qa/programming/Interview";
import InterviewDetails from "./components/interview_qa/programming/InterviewDetails";
import Erp from './components/interview_qa/erp/Erp';
import CloudTechnologies from './components/interview_qa/cloud/CloudTechnologies';
import SoftSkills from './components/interview_qa/soft_skills/SoftSkills';
import ProjectManagement from './components/interview_qa/project_management/ProjectManagement';
import PromotionFormController from './components/auth/admin/promotionControllerForm/promotionFormController.js';
import PromotionHeroAWSImgaeselction from './components/auth/admin/promotionControllerForm/PromotionHeroAWSImgaeselction.js';
import ObRegister from "./components/channelpartners/auth/OnboardingRegister.js"
import CompanyPage from './components/channelpartners/auth/Companypage';

import NewsletterEnquiry from './components/auth/newsletterenquiry/NewsLetterEnquiry';
import NewsLettersList from './components/auth/newsletterenquiry/NewsLettersList';

import OnlineClassEnquiry from './components/auth/OnlineClassEnquiry/OnlineClassEnquiry.js';

import UserJobapplicationDetailsTable from './components/auth/admin/jobapplication/UserJobapplicationDetailsTable.js';
import Services from './components/Homepage/Services';
import SkilledInstructorsCard from './components/Homepage/SkilledInstructorsCard';

import AllInstructors from './components/auth/admin/allinstructors/AllInstructors';
import AddNewInstructor from './components/auth/admin/instructor/AddNewInstructor';
import UpdateInstructor from './components/auth/admin/instructor/UpdateInstructor';
import DemoClassregistration from './components/auth/OnlineClassEnquiry/DemoClassRegistration';
import RemindMeClass from './components/auth/OnlineClassEnquiry/RemindMeclass';
import AllProcessList from './components/auth/admin/allprocess/AllProcessList.js';
import NewslettersList from './components/auth/newsletterenquiry/NewsLettersList.js';
import AddNewProcess from './components/auth/admin/addprocess/AddNewProcess.js';
import UpdateProcessList from './components/auth/admin/addprocess/UpdateProcessList.js';

import Projectlist from './components/Realtimeproject/Projectlist';
import Projectlistdetails from './components/Realtimeproject/Projectlistdetails';


import RealtimeProjectForm from './components/auth/admin/RealtimeProjectFrom/RealtimeProjectForm';

import RealtimeProjectDetils from './components/auth/admin/RealtimeProjectDetailsTable/RealtimeProjectDetils';
import UpdateRealtimeProjectDetails from './components/auth/admin/addrealtimeprojectdetail/UpdateRealtimeProjectDetails.js';

import MaterialDetails from './components/materials/MaterialDetails';
import DownloadMaterial from './components/materials/DownloadMaterial';
import UploadCourseMaterial from './components/auth/admin/addCourseMaterials/UploadCourseMaterial.js';
import AddNewCourseMareial from './components/auth/admin/addCourseMaterials/AddNewCourseMareial.js';
import AllMaterials from './components/auth/admin/allMaterials/AllMaterials.js';
import MaterialList from './components/auth/admin/allMaterials/MaterialList.js';
import AddFile from './components/auth/admin/allMaterials/AddFile.js';

// import MaterialDetails from './components/materials/MaterialDetails';
// import DownloadMaterial from './components/materials/DownloadMaterial';
// import UploadCourseMaterial from './components/auth/admin/addCourseMaterials/UploadCourseMaterial.js';
// import AddNewCourseMareial from './components/auth/admin/addCourseMaterials/AddNewCourseMareial.js';
// import AllMaterials from './components/auth/admin/allMaterials/AllMaterials.js';
// import MaterialList from './components/auth/admin/allMaterials/MaterialList.js';
// import AddFile from './components/auth/admin/allMaterials/AddFile.js';

import CompanyImageGallery from './components/CompanyImage/CompanyImageGallery.js';
import CompanyNameFrom from './components/auth/admin/companyName/CompanyNameFrom.js';
import CompanyPlacementTable from './components/auth/admin/CompanyPlacementTable/CompanyPlacementTable.js';
import UpdateCompanyNamePlacementTableDetails from './components/auth/admin/addcompanyNamePlacementTableDetails/UpdateCompanyNamePlacementTableDetails.js';

function App() {
  const[bgcolor,setBgColor] = useState('')
    
  return (
    <div className="App learningapp" style={{backgroundImage: bgcolor}}>
      <ToastContainer theme='colored' position='top-center'></ToastContainer>
   
     
    
        <BrowserRouter>
        <ShowHeader>
          <Navbar />
        </ShowHeader>    
        <ShowTopbar>
            <TopbarUser />
        </ShowTopbar>  
        <RouteTop>
        <div className='content-top-margin'>
        <Routes>
        <Route exact path="/materials" element={<MaterialDetails/>}/>
          <Route exact path="/DownloadMaterial" element={<DownloadMaterial/>}/>
          <Route exact path='/' element={<Home />} />
          <Route exact path="/about" element={<AboutPage />} />     
          <Route path="/components/Homepage/Onlineclasses" element={<Onlineclasses />} />  
          <Route path="/components/Homepage/Demo" element={<Demo />} /> 
          <Route path="/components/Homepage/OnlineClassRegistration" element={<OnlineClassRegistration />} />
          <Route path="/components/Homepage/OnlineClassRegistration/:courseId" element={<OnlineClassRegistration />} />
          <Route path="/components/Homepage/RemindMe" element={<RemindMe />} />   
          <Route exact path="/programs" element={<Programs />} />     
          <Route exact path="/courses" element={<Courses />} />     
           <Route exact path='/programDetail/:id' element={<ProgramDetail />} />
          <Route exact path='/courses/:id' element={<CourseDetail bgcolor={bgcolor} setBgColor={setBgColor} />} />
          <Route exact path='/register' element={<RegisterForm />} />
          <Route exact path='/login' element={<LoginForm />} />
          <Route exact path='/Realtimeproject' element={ <Projectlist/> } />
           <Route exact path='/Realtimeproject/:id' element={<Projectlistdetails/>} />
           <Route exact path='/CompanyImage' element={<CompanyImageGallery/>}/> 

          {/* <Route exact path='/jobs' element={ <JobsPage /> } /> */}
          {/* <Route exact path='/jobs/:id' element={ <JobDetailsPage/> } /> */}
          <Route exact path='/registerform' element={<RegisterForm />} />
          <Route path='cpregister' element={<CPRegisterForm />}  />
          <Route exact path='/welcome' element={<Welcome />}></Route>
          <Route exact path='/download' element={<DownloadLesson />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/latestEvent/:id' element={<LatestEvent />} />
          <Route exact path='/imageList' element={<GetImagesAws />} /> {/* TESTING remove this route/component later */}
          <Route exact path='/programsWithImage' element={<GetAllProgramswithImage />} /> {/* TESTING remove this route/component later */}
          <Route exact path='/programDetailImage/:id' element={<ProgramDetailwithImages />} /> {/* TESTING remove this route/component later */}
          <Route exact path='/eventregisterform/:eventName' element={<EventRegistrationForm />} />
          <Route exact path='/forgotPassword' element={<ForgotPassword />} />
          <Route exact path='/verificationcode' element={<VerificationCode />} />
          <Route exact path='/changepassword' element={<ChangePassword />} /> 
          <Route exact path='/cplogin' element={<CPLoginForm />} />
          {/* <Route exact path='/tutorials' element={<Tutorials />} /> */}
          <Route path="/tutorials/:technology_id" element={<TutorialDetail />} />
          {/* <Route exact path='/interview' element={<Interview />} /> */}
          {/* <Route exact path='/interview/:category_id' element={<InterviewDetails />} /> */}
          {/* <Route exact path='/interview/erp' element={<Erp />} /> */}
          {/* <Route exact path='/interview/cloud-technologies' element={<CloudTechnologies />} /> */}
          {/* <Route exact path='/interview/soft-skills' element={<SoftSkills />} /> */}
          {/* <Route exact path='/interview/project-management' element={<ProjectManagement />} /> */}
          <Route exact path='/obregister' element={<ObRegister />} />
          <Route path='/companypage' element={<CompanyPage />}  />
          <Route exact path="/Services" element={<Services />} />
          <Route exact path='/SkilledInstructorsCard' element={<SkilledInstructorsCard />} />
          <Route path='/channelPartner'>
            <Route path='Dashboard' element={<CPdashboard />} />
            <Route path='Register' element={<Register />} />
             
          </Route>
          <Route path='/auth' element={<AuthCp />}>
            <Route  path='ExecutorCp' element={<ExecutorDashboard />} />
            <Route  path='ExecutorDashboard' element={<ExecutorDashboard />} />
            <Route  path='updateVisitor/:id' element={<UpdateUser/>} />
            <Route  path='updateEvent' element={<UpdateEvent/>} />
            <Route  path='cpList' element={<ChannelPatner />} />
            <Route  path='eventList' element={<EventList />} />
            <Route  path='subscriberEvents' element={<SubscriberEvents />} />
            
            <Route  path='allevents' element={<AllEvents />} />
           <Route path='AdminCp' element={<AdminDashBoard />} />
            <Route  path='AdminDashboard' element={<AdminDashBoard />} />
            {/* admin CRUD user  */}
            <Route  path='createNewUser' element={<CreateNewUser />} />
            <Route  path='updateUser/:id' element={<UpdateUser />} />
            {/* admin CRUD programs */}
            <Route  path='addNewProgram' element={<AddNewProgram />} />
            <Route  path='allPrograms' element={<AllPrograms />} />
            <Route  path='updateProgram/:id' element={<UpdateProgram />} />
              {/* admin CRUD image */}
            <Route  path='uploadImage' element={<UploadImage />} />
            <Route  path='imageGallery' element={<ImageGallery />} />
            <Route  path='imageControl' element={<ImageManagement />} />
           <Route  path='dashboard' element={<Dashboard />} />
            {/* admin CRUD course */}
            <Route  path='allCourses' element={<AllCourses />} />
            <Route  path='addNewCourse' element={<AddNewCourse />} />
            <Route  path='updateCourse/:id' element={<UpdateCourse />} />
            <Route  path='addNewLesson' element={<AddNewLesson />} />
            <Route  path='addNewUnit' element={<AddNewUnit />} />
            <Route  path='addNewPrerequisite' element={<AddNewPrerequisite />} />
            <Route  path='allLessons' element={<AllLessons/>} />
            <Route  path='updateLesson/:id' element={<UpdateLesson />} />
            <Route  path='jobUploaderForm' element={<JobUploaderForm/>} />
            <Route  path='allUnits' element={<AllUnits />} />
            <Route  path='updateUnit/:id' element={<UpdateUnit />} />
            <Route  path='allPrerequisites' element={<AllPrerequisites/>} />
            <Route  path='updatePrerequisite/:id' element={<UpdatePrerequisite />} />
            <Route  path='promotionControllerForm' element={<PromotionFormController/>} />
            <Route path='promotionHeroAWSImgaeselction' element={<PromotionHeroAWSImgaeselction />} />
            <Route path='OnlineClassEnquiry' element={<OnlineClassEnquiry/>}/>
            <Route path="/auth/DemoClassRegistration" element={<DemoClassregistration />} />
            <Route path="RemindMeclass" element={<RemindMeClass />} />
            <Route path='allprocesslist'element={<AllProcessList/>}/>
            <Route path='addnewprocess' element={<AddNewProcess/>}/>
            <Route path='updateprocesslist/:programId/:courseId'element={<UpdateProcessList/>}/>

            <Route  path='realtimeProjectFrom' element={<RealtimeProjectForm/>} />
            <Route  path='realtimeProjectDetails' element={<RealtimeProjectDetils/>} />
            <Route  path='updateRealtimeProjectDetails/:project_name' element={<UpdateRealtimeProjectDetails/>} />


            <Route path="UserJobapplicationDetailsTable" element={<UserJobapplicationDetailsTable />} />


            <Route path='companyName' element={<CompanyNameFrom />}/> 
              <Route path ='companyPlacementTable' element={<CompanyPlacementTable/>}/>
              <Route path='updateCompanyNamePlacementTableDetails/:Company_name' element={<UpdateCompanyNamePlacementTableDetails/>}/>

            {/* admin CRUD programcourse linking */}
            <Route  path='allCategory' element={<MakeCategory />} />
            <Route path='cpdashboard' element={<CPDashboard />}  />
            {/* admin CRUD promotion */}
              {/* Events */}
              <Route  path='allEvents' element={<AllEvents />} />
              <Route  path='allPromotions' element={<AllPromotions />} />
              <Route  path='addPromotion' element={<AddPromotion />} />
            {/* subscribers enquiry from promotion banner */}  
            
            {/* admin CRUD faqs */}
            <Route  path='allFaqs' element={<AllFaqs />} />
            <Route  path='addNewFaq' element={<AddNewFaq />} />
            <Route  path='updateFaq/:id' element={<UpdateFaq />} />
            <Route  path='eventSubscriberEnquiry' element={<EventSubscriber/>} />
            <Route  path='newslettersList' element={<NewsLettersList />}/>
            <Route  path='newsletterEnquiry' element={<NewsletterEnquiry />} />
            {/* admin CRUD events */}
            <Route  path='uploadEvent' element={<UploadEvent/>} />
            {/* admin CRUD instructor */}
            <Route  path='allInstructors' element={<AllInstructors />} />
            <Route  path='addNewInstructor' element={<AddNewInstructor />} />
            <Route  path='updateInstructor/:id' element={<UpdateInstructor />} />
            <Route path='AddNewCourseMaterial' element={<AddNewCourseMareial/>}/>
            <Route path='UploadCourseMaterial' element={<UploadCourseMaterial/>}/>
            <Route path='allMaterials' element={<AllMaterials/>}/>
            <Route path='MaterialList' element={<MaterialList/>}/>
            <Route path='AddFile/:id' element={<AddFile/>}/>
            </Route>
        </Routes>
        </div>
        </RouteTop>
      <ShowHeader>
      {/* <ChatBotFunc/>we will use in the future */}
        <Footer />
      </ShowHeader>
      </BrowserRouter>
      {/* coockie consent */}
      <CookieBanner />
    </div>
  );
}

export default App;
